import { Public } from '@mui/icons-material';
import React from 'react';
import Footer from './Footer';
import PublicHeader from './PublicHeader';
import './layouts.scss';
import InitHeader from './InitHeader';

const InitLayout = ({ children }) => {
    return (<>
        <InitHeader />
        <div className='screen'>
            <div className='screen__content'>
                {children}
                {/* <div className='init__content'><h4>EE PASS is temporarily down for maintenance. Our team is working hard to investigate and resolve the issue as quickly as possible. We apologize for any inconvenience this may cause.</h4></div> */}
            </div>
        </div>
        <Footer />
    </>
    );
}

export default InitLayout;


