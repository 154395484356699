   
import _ from "lodash";
import { ItemServiceFactory } from "../services/ItemServiceFactory";
import ItemManager from "./ItemManager";
import SystemTypes from '../SystemTypes';

export default class UserManager extends ItemManager {

    constructor(userState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.UserProfile, globalAuthState, globalAppUserState, userState)
        this._clientMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.Client, globalAuthState, globalAppUserState);
        this._schoolMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.School, globalAuthState, globalAppUserState);
        this._districtMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.District, globalAuthState, globalAppUserState);
        this._clusterMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.Cluster, globalAuthState, globalAppUserState);
        this._roleMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.Role, globalAuthState, globalAppUserState);
        this._observationConfigMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState);
        this._schoolYearUserMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.SchoolYearUser, globalAuthState, globalAppUserState);
        this._userState = userState;
        this._setAllUsers = (value) => userState.merge({ allUsers: value });
        this._setForceUserUpdate = (value) => userState.merge({ forceUpdate: value });
        this._setFilteredUsers = (value, filter) => userState.merge({ filteredUsers: value, userFilter: filter });
        this._schoolUserPositionsMgr = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.SchoolUserPositions, globalAuthState, globalAppUserState);
    }

    get allUsers() { return this._userState?.get({ noproxy: true }).allUsers; }
    get _forceUserUpdate() { return this._userState?.get({ noproxy: true }).forceUpdate; }
    get _userFilter() { return this._userState?.get({ noproxy: true }).userFilter; }
    get FilteredUsers() { return this._userState?.get({ noproxy: true }).filteredUsers; }

    get ItemManager() {
        return this;
    }

    get ClientManager() {
        return this._clientMgr;
    }

    get ClusterManager() {
        return this._clusterMgr;
    }

    get SchoolManager() {
        return this._schoolMgr;
    }

    get DistrictManager() {
        return this._districtMgr;
    }

    get RoleManager() {
        return this._roleMgr;
    }

    async FilterUsers(filter, userToAdd) {
        if (!filter) {
            filter = {
                Search: this._userFilter.Search,
                EEPass: this._userFilter.EEPass,
                userInGroup: this._userFilter.userInGroup,
                IsActive: this._userFilter.IsActive
            };
        }

        let allUsers = this.allUsers.map(x => x);
        if (userToAdd) {
            allUsers = _.filter(allUsers, x => x.Id !== userToAdd.Id);
            allUsers = [...allUsers, userToAdd];
            this._setAllUsers(allUsers);
        }
        return this._doFilter(filter, allUsers);
    }

    async _doFilter(filter, allUsers) {
        if (!allUsers) {
            allUsers = this.allUsers.map(x => x);
        }

        const filteredUsers = _.filter([...allUsers], t => {
            let searchFound = false;
            if (filter?.Search !== '') {
                if (t.FirstName?.toLowerCase()?.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                    t.LastName?.toLowerCase()?.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                    `${t.FirstName?.toLowerCase()} ${t.LastName?.toLowerCase()}`.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                    `${t.LastName?.toLowerCase()}, ${t.FirstName?.toLowerCase()}`.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                    `${t.LastName?.toLowerCase()},${t.FirstName?.toLowerCase()}`.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                    `${t.LastName?.toLowerCase()} ${t.FirstName?.toLowerCase()}`.startsWith(filter?.Search?.toLowerCase() ?? '') ||
                    `${t.RoleData?.toLowerCase()} ${t.RoleData?.toLowerCase()}`.includes(filter?.Search?.toLowerCase() ?? '') ||
                    t.Email?.toLowerCase()?.includes(filter?.Search?.toLowerCase() ?? '')) {
                    searchFound = true;
                }
            }
            else {
                searchFound = true;
            }

            let eepassFound = false;
            if (filter?.EEPass !== '') {
                if (t.SystemId?.endsWith(filter?.EEPass ?? '')) {
                    eepassFound = true;
                }
            }
            else {
                eepassFound = true;
            }
            let userInGroupFound = false

            if (filter?.userInGroup && filter?.userInGroup.length > 0) {
                userInGroupFound = filter.userInGroup.filter(x => x.startsWith('o-')).some(x => t.OrganizationIds.includes(x.replace('o-', '').toLowerCase()) || (x.toLowerCase() === "o-" && t.OrganizationIds.length === 0));
                if (!userInGroupFound)
                    userInGroupFound = filter.userInGroup.filter(x => x.startsWith('d-')).some(x => t.DistrictIds.includes(x.replace('d-', '').toLowerCase()) || (x.toLowerCase() === "d-" && t.DistrictIds.length === 0));
                if (!userInGroupFound)
                    userInGroupFound = filter.userInGroup.filter(x => x.startsWith('s-')).some(x => t.SchoolIds.includes(x.replace('s-', '').toLowerCase()) || (x.toLowerCase() === "s-" && t.SchoolIds.length === 0));
            } else {
                userInGroupFound = true
            }

            let activeFound = false;
            if (filter?.IsActive !== '') {
                if (filter?.IsActive === t.IsActive.toString()) {
                    activeFound = true;
                }
            }
            else {
                activeFound = true;
            }

            let jobTitleFound = false;
            if (filter?.JobTitle !== 'UNK' && filter?.JobTitle !== '') {
                jobTitleFound = filter?.JobTitle == t.UserPositionType;
            } else {
                jobTitleFound = true;
            }
            return activeFound && searchFound && userInGroupFound && eepassFound && jobTitleFound;
        });
        this._setFilteredUsers(filteredUsers, filter);
        return filteredUsers;
    }

    async DeleteItem(id) {
        const result = await this.delete(id);
        if (result.Success) {
            let allUsers = [...this.allUsers];
            allUsers = _.filter(allUsers, x => x.Id !== id);
            return {
                Success: true,
                Items: await this._doFilter(this._userFilter, allUsers)
            };
        }
        else {
            return result;
        }
    }
    async GetObservationConfig() {
        const currentSchoolYear = this.AppUserState.selectedSchoolYear
        const result = await this._observationConfigMgr.list(this.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false && x.SchoolYearIdentifier == currentSchoolYear ", { currentSchoolYear }))
        return result
    }

    async MergeUsers(userIdMergeTo, userIdMergeFrom){
        return await this.runOperation('MergeUserProfile', userIdMergeTo, undefined, { uidmf: userIdMergeFrom });
    }

    async SearchUsers() {
        let result = await this.runOperation('GetUserProfileList', undefined, undefined, { isStaged: "0" })
        if (result.Success) {
            result.Items = _.map(result.Items.first().Data, x => {
                return {
                    ...x,
                    userPositionName: _.find(this?.AppUserState?.observationConfig.UserPositionAlternativeMap, y => y.UserPosition === x.UserPositionType && y.Name)?.Name ?? Object.fromEntries(Object.entries(SystemTypes.UserPositionType).map(a => a.reverse()))[x.UserPositionType],
                }
            })
            this._setAllUsers(result.Items);
            const filteredUsers = await this._doFilter(_.cloneDeep(this._userFilter), result.Items);
            return filteredUsers;
        }
        else
            return [];
    }

    async getSchoolYearUser(userId) {
        const currentSchoolYear = this.AppUserState.selectedSchoolYear;
        let syUser = await this._schoolYearUserMgr.list(this.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false && x.UserId == userId && x.SchoolYearIdentifier == currentSchoolYear ", { userId, currentSchoolYear }))
        return syUser;
    }

    async getUserPositionsForSchool(schoolId) {
        console.log("UserManager.getUserPositionsForSchool " + schoolId);
        let result = await this._schoolUserPositionsMgr.getBySchool(schoolId);
        return result;
    }
}