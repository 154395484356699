import React, { useState, useTransition } from 'react';
import _, { set } from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../components/layout/screenWrapper';
import MainLayout from '../../components/layout/MainLayout';
import { useCertificationContext } from './components/CertificationDataProvider';
import CertificationTeacherDetails from './components/teachercertification/details';
import CertificationTeacherFilter from './components/teachercertification/filter';
import CertificationTeacherList from './components/teachercertification/table';
import { default as Print } from './components/certificationprint/PrintCertification';
import moment from 'moment';
import { DialogControl } from '../../components/controls/DialogControl';
import { SelectListControl } from '../../components/controls';
import ButtonControl from '../../components/controls/ButtonControl';
import SystemTypes from '../../SystemTypes';
import BitmovinPlayer from '../contentLibrary/app-components/Content/Player/BitmovinPlayer';

const CertificationTeachers = () => {
    const { managerIsReady, certificationTeacherMgr, certificationRatingMgr, observationMgr, userMgr, orgMgr, userProfile, appUserMgr } = useCertificationContext();
    const [manageMode, setManageMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([])
    const [gridData, setGridData] = useState(null)
    const [ratingSelectOptions, setRatingSelectOptions] = useState(null)
    const [data, setData] = useState(null)
    const [loadingRubric, setLoadingRubric] = useState(false)
    const [observationConfig, setObservationConfig] = useState(null)
    const [observationConfigPrint, setObservationConfigPrint] = useState(null)
    const [saving, setSaving] = useState(false)
    const [openResetCertificationDialog, setOpenResetCertificationDialog] = useState(false)
    const [openManualContent, setOpenManualContent] = useState(false)
    const [openManuallyPassCert, setOpenManuallyPassCert] = useState(false)
    const [formattedContents, setFormattedContents] = useState(null)
    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedCeretification, setSelectedCertification] = useState(null)
    const [certificationHistory, setCertificationHistory] = useState(null)
    const [openManuallyPassPart1Cert, setOpenManuallyPassPart1Cert] = useState(null)
    const [openResetCertificationQuestionsDialog, setOpenResetCertificationQuestionsDialog] = useState(false)
    const [orgs, setOrgs] = useState(null)
    const [print, setPrint] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (managerIsReady) {
            setLoading(true)
            handleParamChange();
            orgMgr.loadOrgTree(true, false).then((r) => {
                setOrgs(r)
            })
        }
    }, [managerIsReady, params]);

    const [canCertify, setCanCertify] = useState(null);
    const statusIdentifer = (item) => {
        if (item?.Status == 9) {
            return "Manually Passed"
        }
        else if (item?.Status == 0 || item?.Status == 7) {
            return "Not Started"
        } else if (item?.Status === 1) {
            return "Part I In Progress"
        } else if (item?.Status === 2) {
            return "Pass Part 1"
        } else if (item?.Status === 3) {
            return "Fail Part 1"
        } else if (item?.Status === 4) {
            return "Part II In Progress"
        } else if (item?.Status === 5) {
            return "Passed"
        } else if (item?.Status === 6) {
            return "Failed"
        }
    }
    useEffect(() => {
        if (managerIsReady && certificationTeacherMgr?._allData && canCertify === null) {
            const updatedCert = certificationTeacherMgr._allData.find(x => x.UserId === appUserMgr.AppUserState.userProfile.Id)
            if (updatedCert) {
                updatedCert.Status = statusIdentifer(updatedCert)
                setCanCertify(updatedCert);
            } else {
                let RolesSet = appUserMgr.AppUserState.userProfile.Roles;
                let isSysAdmin = appUserMgr.AppUserState.userProfile.Roles.some(role => role === 'SysAdmin');
                if (!isSysAdmin) {
                    let newCertforUser = {
                        UserId: appUserMgr.AppUserState.userProfile.Id,
                        Status: 'Admin' //not started, is admin
                    }
                    setCanCertify(newCertforUser);
                }
            }
        }
    }, [certificationTeacherMgr?._allData, managerIsReady]);

    const handleParamChange = async () => {
        // Check if they have permission to see list. Change config
        // Permission for a VIEW only answer.
        // Permission to EDIT the answers too.
        if (params.id) {
            const userId = params.id
            const currentSchoolYear = certificationTeacherMgr.AppUserState.currentSchoolYear
            certificationTeacherMgr.list(certificationTeacherMgr.buildJSONObjFilter("x => x.IsActive == true && x.IsDeleted == false && x.CertificationType == 2 && x.UserId == userId && x.SchoolYearIdentifier != currentSchoolYear || ((x.Status == 5 || x.Status == 9) && x.SchoolYearIdentifier == currentSchoolYear)", { userId, currentSchoolYear })).then(r => setCertificationHistory(r.Items))
            setManageMode('view')
            await certificationTeacherMgr.getSingleCertificationWithUserId(params.id).then(result => {
                if (result.Success) {
                    if (result.Items.length === 0) {
                        setErrors(["User is not configured to be evaluated for a certification."])
                    } else {
                        setData(result.Items.first())
                        setLoadingRubric(true)
                        if (result.Items.first().Questions) {
                            handleGrabRubric(result.Items.first().Id)
                        }
                        const orgId = result.Items.first().OrganizationIds.first()
                        const districtId = result.Items.first().DistrictIds.first()
                        const schoolId = result.Items.first().SchoolIds.first()
                        const schoolYear = result.Items.first().SchoolYearIdentifier
                        observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
                            if (r) {
                                setObservationConfig(r)
                                setLoading(false)
                            } else console.log("No observation ConfigFound")
                        })
                    }
                } else {
                    setErrors(result.MessageDetails)
                }
            })
        } else {
            await certificationTeacherMgr.search(undefined, true).then(r => {
                if (_.every(r, x => x.UserId === userProfile.Id)) {
                    navigate(`/certifications/teacher/${userProfile.Id}`)
                } else {
                    setData(r)
                    setLoading(false)
                    setManageMode('list')
                }
            })
        }
    }

    const handleGrabRubric = (certId) => {
        certificationTeacherMgr.getCertificationRubric(certId).then(r => {
            certificationRatingMgr.transformRubric(r).then(tr => {
                setGridData(tr.gridData);
                if (tr.tagMap) {

                    let ratingSelectOpts = {};

                    Object.keys(tr.tagMap).forEach(t => {
                        let selectedRatingValue = tr.gridData.data?.reduce((r, cv) => {
                            if (tr.tagMap[t].name === cv.tag?.name && cv.selectedRating !== '') {
                                r = cv.selectedRating;
                            }
                            return r;
                        }, null)

                        ratingSelectOpts[tr.tagMap[t].name] = {
                            isExpanded: false, surveyRatingId: tr.gridData.data?.reduce((r, cv) => {
                                if (tr.tagMap[t].name === cv.tag?.name) {
                                    r = cv.surveyRatingId
                                }
                                return r;
                            }, null),
                            selectedRating: selectedRatingValue,
                            rubricRatingId: _.find(tr.gridData.metaData, y => y.rating === selectedRatingValue)?.rubricRatingId,
                            rubricIndicatorId: tr.tagMap[t].id
                        };
                    })
                    setRatingSelectOptions(ratingSelectOpts);
                    setLoadingRubric(false)
                }
            })
        })
    }

    const createNewCertificate = async (certificationData, isStarted) => {
        if (!certificationData) {
            certificationData = data
        }
        const status = certificationData?.Status === 4 ? 4 : isStarted ? 1 : 0
        let result = await certificationTeacherMgr.save({ ...certificationData, User: undefined, Status: status, StartedDate: isStarted ? moment().utc().toISOString() : undefined }).then(r => {
            if (r.Success && isStarted) {
                return certificationTeacherMgr.getSingleCertificationWithUserId(r.Items.first().UserId).then(r => {
                    if (!r.Items.first().ContentId && r.Items.first().Status !== 5 && r.Items.first().Status !== 9) {
                        setErrors(['Maximum content available has been reached. Please contact NIET support'])
                    } else {
                        setData(r.Items.first())
                        handleGrabRubric(r.Items.first().Id)
                        return r.Items.first()
                    }
                })
            } else if (r.Success && !isStarted) {
                //This is to reload the data just incase they click on manual content which saves a new cert but don't actually save a new content yet.
                handleParamChange();
                return r.Items.first()
            } else console.log('fail to save new certificate.')
        })
        return result
    }

    const saveCertification = (certification) => {
        return certificationTeacherMgr.save(certification)
    }

    const handleOnEdit = (row, fromTable) => {

        if (!row.ContentId && (row.Status !== "Passed" && row.Status !== "Manually Passed") && !row.Status === 'Admin') {
            setErrors(['Maximum content available has been reached. Please contact NIET support'])
        } else if (!row.Rubric && !row.RubricId && row.Status !== "Passed" && row.Status !== "Manually Passed" && !row.Status === 'Admin') {
            {
                setErrors(['User has no available rubrics. Please contact NIET support'])
            }
        } else {

            navigate(`/certifications/teacher/${row.UserId}`)
        }
    }
    const handleOpenPrint = async (row, certData) => {
        setSelectedCertification(row)
        let grabbingData = true;
        if (!certData) {
            await certificationTeacherMgr.getSingleCertificationWithUserId(row.UserId).then(async (result) => {
                if (result.Success) {
                    const orgId = result.Items.first().OrganizationIds.first()
                    const districtId = result.Items.first().DistrictIds.first()
                    const schoolId = result.Items.first().SchoolIds.first()
                    const schoolYear = result.Items.first().SchoolYearIdentifier
                    await observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
                        grabbingData = false;
                        if (r) {
                            setObservationConfigPrint(r)
                        } else console.log("No observation ConfigFound")
                    })
                }
            })
        } else {
            await certificationTeacherMgr.getSingleCertificationWithUserId(row.UserId, certData.SchoolYearIdentifier, 2).then(async (result) => {
                if (result.Success) {
                    const orgId = result.Items.first().OrganizationIds.first()
                    const districtId = result.Items.first().DistrictIds.first()
                    const schoolId = result.Items.first().SchoolIds.first()
                    const schoolYear = result.Items.first().SchoolYearIdentifier
                    await observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
                        grabbingData = false;
                        if (r) {
                            setObservationConfigPrint(r)
                        } else console.log("No observation ConfigFound")
                    })
                }
            })
        }
        if (grabbingData === false) {
            setPrint(true);
            return true;
        }
    }

    const handleClosePrint = () => {
        setSelectedCertification(null)
        setObservationConfigPrint(null);
        setPrint(false)
    }

    const handlePassingCertification = (row, part2) => {
        if (part2) {
            setOpenManuallyPassCert(true)
        } else {
            setOpenManuallyPassPart1Cert(true)
        }
        setSelectedCertification(row)
    }
    const resetCertification = async (row) => {
        setOpenResetCertificationDialog(true)
        setSelectedCertification(row)
    }

    const resetCertificationQuestions = async (row) => {
        setOpenResetCertificationQuestionsDialog(true)
        setSelectedCertification(row)
    }

    const updateUserPermissions = () => {
        const userProfile = { ...data?.User }
        userProfile.RoleData = [...userProfile.Roles, 'CertifiedTeacher'].join(',')
        userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true).then(() => sessionStorage.setItem('profile-update-force-token', true))
    }

    const manuallySelectCertContent = async (row) => {
        const copiedCertification = { ...row }
        copiedCertification.Status = 0
        copiedCertification.userFullName = undefined
        copiedCertification.fullOrg = undefined
        setSelectedCertification(row)
        const hasCertStarted = await certificationTeacherMgr.get(row.Id).then(r => { return r.Success })
        if (hasCertStarted) {
            await certificationTeacherMgr.grabCertificationContent(row.Id, row.UserId).then(content => setFormattedContents(content.Items))
            setOpenManualContent(true)
        }
        else {
            await createNewCertificate(copiedCertification, false).then(async (r) => {
                await certificationTeacherMgr.grabCertificationContent(r.Id, r.UserId).then(content => setFormattedContents(content.Items))
                setOpenManualContent(true)
            })
        }
    }
    const handleOnFilterChange = async (field, value) => {
        const certificationFilter = certificationTeacherMgr.Filter;
        const updatedCertificationFilter = { ...certificationFilter };
        updatedCertificationFilter["Identifier"] = 'Teacher';
        updatedCertificationFilter[field] = value !== '' ? value : null;
        if (field === "groups") {
            const orgs = []
            const districts = []
            const schools = []
            const users = []
            value.map(group => {
                if (group.substring(0, 2) === 'o-') {
                    orgs.push(group.substring(2, group.length))
                } else if (group.substring(0, 2) === 'd-') {
                    districts.push(group.substring(2, group.length))
                } else if (group.substring(0, 2) === 's-') {
                    schools.push(group.substring(2, group.length))
                } else if (group.substring(0, 2) === 'u-') {
                    users.push(group.substring(2, group.length))
                }
            })
            updatedCertificationFilter["orgs"] = orgs
            updatedCertificationFilter["districts"] = districts
            updatedCertificationFilter["users"] = users
            updatedCertificationFilter["schools"] = schools
        }
        await certificationTeacherMgr.filterData(updatedCertificationFilter);
    }


    const takeCertification = async () => {
        if (canCertify)
            return await handleOnEdit(canCertify);
        else {
            setErrors(["Current logged in user is not able to be certified."]);
        }

    }
    return (
        <>
            {print && <Print close={handleClosePrint} data={selectedCeretification} observationConfig={observationConfigPrint} certType={2} />}
            <DialogControl
                title={`Are you sure you want to reset part 1 of the certification for ${selectedCeretification?.User?.FullName}? `}
                openDialog={openResetCertificationDialog}
                onCancel={() => {
                    setOpenResetCertificationDialog(false)
                }}
                loadingSave={saving}
                disableXButton={true}
                onOk={async () => {
                    setSaving(true)
                    const userProfile = { ...selectedCeretification?.User }
                    userProfile.RoleData = _.filter(userProfile.RoleData.split(','), x => x !== 'CertifiedTeacher').join(',')
                    const resetUserCertStatus = userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true)
                    const resetCert = certificationTeacherMgr.resetCertificate(userProfile.Id)
                    await Promise.all([resetUserCertStatus, resetCert]).then(async (r) => {
                        if (_.every(r, x => x.Success === true)) {
                            setSaving(false);
                            setOpenResetCertificationDialog(false);
                            setSelectedCertification(null);
                            await handleParamChange();
                        }
                    })
                }}
            />
            <DialogControl
                title={`Are you sure you want to reset part 2 of the certification for ${selectedCeretification?.User?.FullName}? `}
                openDialog={openResetCertificationQuestionsDialog}
                onCancel={() => {
                    setOpenResetCertificationQuestionsDialog(false)
                }}
                loadingSave={saving}
                disableXButton={true}
                onOk={async () => {
                    setSaving(true)
                    const userProfile = { ...selectedCeretification?.User }
                    const copiedCertification = { ...selectedCeretification }
                    copiedCertification._id = undefined
                    copiedCertification.User = undefined
                    copiedCertification.Status = 4
                    copiedCertification.Id = undefined
                    copiedCertification.UniqueId = undefined
                    copiedCertification.Questions = undefined
                    if (userProfile.RoleData.includes('CertifiedTeacher')) {
                        userProfile.RoleData = _.filter(userProfile.RoleData.split(','), x => x !== 'CertifiedTeacher').join(',')
                        const resetUserCertStatus = userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true)
                        const resetCert = certificationTeacherMgr.resetCertificate(userProfile.Id).then(async (resetResult) => {
                            return await certificationTeacherMgr.save(copiedCertification)
                        })
                        await Promise.all([resetUserCertStatus, resetCert]).then(async (r) => {
                            if (_.every(r, x => x.Success === true)) {
                                setSaving(false);
                                setOpenResetCertificationQuestionsDialog(false);
                                await handleParamChange();
                            }
                        })
                    } else {
                        await certificationTeacherMgr.resetCertificate(userProfile.Id).then(async (resetResult) => {
                            await certificationTeacherMgr.save(copiedCertification).then(async (saveResult) => {
                                setSaving(false);
                                setOpenResetCertificationQuestionsDialog(false);
                                await handleParamChange();
                            })
                        })
                    }

                }}
            />
            <DialogControl
                title={`Are you sure you want to manually pass part 1 for ${selectedCeretification?.User?.FullName}?`}
                openDialog={openManuallyPassPart1Cert}
                onCancel={() => {
                    setOpenManuallyPassPart1Cert(false)
                }}
                loadingSave={saving}
                disableXButton={true}
                onOk={async () => {
                    setSaving(true)
                    const copiedCert = { ...selectedCeretification }
                    copiedCert.Status = 2;
                    copiedCert.StartedDate = copiedCert.StartedDate ? copiedCert.StartedDate : moment().utc().toISOString()
                    copiedCert.CompletedDatePart1 = moment().utc().toISOString()
                    const saveCert = await certificationTeacherMgr.save(copiedCert)
                    await handleParamChange()
                    setSaving(false)
                    setOpenManuallyPassPart1Cert(false)
                }}
            />
            <DialogControl
                title={`Are you sure you want to manually certify  ${selectedCeretification?.User?.FullName}?`}
                openDialog={openManuallyPassCert}
                onCancel={() => {
                    setOpenManuallyPassCert(false)
                }}
                loadingSave={saving}
                disableXButton={true}
                onOk={async () => {
                    setSaving(true)
                    const copiedCert = { ...selectedCeretification }
                    copiedCert.Status = 9;
                    copiedCert.StartedDate = copiedCert.StartedDate ? copiedCert.StartedDate : moment().utc().toISOString()
                    copiedCert.CompletedDate = copiedCert.CompletedDate ? copiedCert.CompletedDate : moment().utc().toISOString()
                    const userProfile = { ...selectedCeretification?.User }
                    userProfile.RoleData = [...userProfile.Roles, 'CertifiedTeacher'].join(',')
                    const saveCert = certificationTeacherMgr.save(copiedCert)
                    const updateUserProfile = userMgr.ItemManager.runOperation('AzureB2CSaveUserProfile', undefined, userProfile, undefined, undefined, true)
                    await Promise.all([saveCert, updateUserProfile]).then(async (r) => {
                        await handleParamChange()
                        setSaving(false)
                        setOpenManuallyPassCert(false)
                    })
                }}
            />
            <DialogControl
                title={`Select Content to Assign`}
                openDialog={openManualContent}
                onCancel={() => {
                    setOpenManualContent(false)
                    setSelectedContent(null)
                    setFormattedContents(null)
                }}
                loadingSave={saving}
                className={' certification-custom-content-dialog'}
                disableXButton={true}
                disableOk={!selectedContent}
                onOk={async () => {
                    setSaving(true)
                    const copiedCert = { ...selectedCeretification }
                    copiedCert.ContentId = selectedContent?.Id
                    copiedCert.Status = 0;
                    await certificationTeacherMgr.save({ ...copiedCert, User: undefined })
                    await handleParamChange();
                    setSaving(false)
                    setSelectedContent(null)
                    setOpenManualContent(false)
                    setFormattedContents(null)
                }}
            >
                <SelectListControl value={selectedContent?.FileId ?? selectedCeretification?.Content?.FileId}
                    field={{
                        FieldName: 'video'
                    }}
                    onChange={(e) => {
                        if (e.target.value === "UNK") {
                            setSelectedContent(null)
                        } else {
                            setSelectedContent(_.find(formattedContents, x => x.FileId === e.target.value))
                        }
                    }}
                    textValuePairs={_.map(formattedContents, x => { return { Text: `${x.DisplayFileName}${((x.FileId && x.FileId === selectedCeretification?.Content?.FileId) ? ' (Assigned)' : '')}`, Value: x.FileId } })} />
                {selectedContent ? 
                
                    <div className='video-player'>
                            <div id='player-wrapper'>
                                <BitmovinPlayer content={selectedContent?.File} />
                            </div>
                        </div>
                :
                    <div style={{ minHeight: "25rem" }} />}
            </DialogControl>
            <MainLayout errors={errors} className={manageMode === 'view' && !loading ? 'certification-page' : ''}>
                <ScreenWrapper loading={loading} >
                    {(manageMode === 'view') && <CertificationTeacherDetails
                        certificationTeacherMgr={certificationTeacherMgr}
                        observationConfig={observationConfig}
                        data={data}
                        createNewCertificate={createNewCertificate}
                        loadingRubric={loadingRubric}
                        gridData={gridData}
                        ratingSelectOptions={ratingSelectOptions}
                        setRatingSelectOptions={setRatingSelectOptions}
                        saveCertification={saveCertification}
                        certificationHistory={certificationHistory}
                        certificationRatingMgr={certificationRatingMgr}
                        setErrors={setErrors}
                        setCertificationHistory={setCertificationHistory}
                        handleOpenPrint={handleOpenPrint}
                        updateUserPermissions={updateUserPermissions}
                    />}
                    {(manageMode === 'list' || manageMode === 'print') &&
                        <div className='control-box-wrapper'>
                            <div className='control-box-list'>
                                <CertificationTeacherFilter
                                    certificationTeacherMgr={certificationTeacherMgr}
                                    onFilterChange={handleOnFilterChange}
                                    orgMgr={orgMgr}
                                    orgs={orgs} />
                                <CertificationTeacherList
                                    certificationTeacherMgr={certificationTeacherMgr}
                                    filteredItems={certificationTeacherMgr?.FilteredData ?? []}
                                    onEdit={handleOnEdit}
                                    onPrint={handleOpenPrint}
                                    resetCertification={resetCertification}
                                    handlePassingCertification={handlePassingCertification}
                                    manuallySelectCertContent={manuallySelectCertContent}
                                    resetCertificationQuestions={resetCertificationQuestions}
                                    appUserMgr={appUserMgr}
                                />

                            </div>
                            <div className='screen-footer list'>
                                {canCertify && <ButtonControl type={"play"} onClick={takeCertification}>{`${(canCertify?.Status === 5 || canCertify?.Status === 9) ? 'View' : 'Take'} Certification`}</ButtonControl>}
                            </div>
                        </div>
                    }
                    {/* {
                        manageMode === 'viewResult' && <GeneralSurveyResults data={data} rubricData={gridData} rubricLoading={rubricLoading} />
                    } */}
                </ScreenWrapper>
            </MainLayout>
        </>
    );
}

export default CertificationTeachers;