import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/layout/MainLayout";
import ScreenWrapper from "../../../components/layout/screenWrapper";
import "./MyDashboard.scss";
import NotificationDashboard from "./components/NotificationDashboard";
import { useAppUserManager, useCertificationTeacherManager, useContentManager, useObservationManager } from "../../../hooks/useManagers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser as userIcon, faBriefcase as briefcaseIcon } from "@fortawesome/free-solid-svg-icons";
import ObservationDashboard from "./components/ObservationDashboard";
import CertificationDashboard from "./components/CertificationDashboard";
import { default as Print } from '../../certifications/components/certificationprint/PrintCertification'

import ContentDashboard from "./components/ContentDashboard";


function MyDashboard() {
  const contentMgr = useContentManager();
  const observationMgr = useObservationManager();
  const globalState = contentMgr?.GetGlobalState();
  const certificationTeacherMgr = useCertificationTeacherManager();

  const appUserMgr = useAppUserManager();
  const navigate = useNavigate();

  const globalConfig = appUserMgr?.GetGlobalState();
  const userProfile = appUserMgr?.GetGlobalState()?.userProfile;

  const [errors, setErrors] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [loadingError, setLoadingError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userTitle, setUserTitle] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [userId, setUserId] = useState("");

  const [dashboard, setDashboard] = useState(null);
  const [minContentWidth, setMinContentWidth] = useState(0);


  const [observationConfigPrint, setObservationConfigPrint] = useState(null)
  const [selectedCertification, setSelectedCertification] = useState(null)
  const [certType, setCertType] = useState();
  const [print, setPrint] = useState(false);
  useEffect(() => {
    if (userProfile) {
      setLoading(true);
      setUserId(userProfile?.UserId);
      setOrganizationId(
        userProfile?.Organizations.length > 0
          ? userProfile?.Organizations[0].ClientId
          : ""
      );
      setDistrictId(
        userProfile?.Districts.length > 0 ? userProfile?.Districts[0].ClientId : ""
      );
      setSchoolId(
        userProfile?.Schools.length > 0 ? userProfile?.Schools[0].ClientId : ""
      );
      // TODO add clusters
      let newUserTitle = `
        ${globalConfig.SchoolNames ? globalConfig.SchoolNames + ` (${globalConfig.selectedSchoolYear - 1} - ${globalConfig.selectedSchoolYear}) > ` : ''} 
        ${globalConfig.DistrictNames ? globalConfig.DistrictNames + ' > ' : ''} 
        ${globalConfig.OrganizationNames ? globalConfig.OrganizationNames : ''}
      `;
      if (newUserTitle.trim() === '' && userProfile?.Roles?.length > 0) newUserTitle = userProfile?.Roles?.join(', ');
      setUserTitle(newUserTitle);
    }
  }, [userProfile]);

  useEffect(() => {
    if (observationMgr) {
      if (appUserMgr.AccessCheck(['ObservationMyDashboard'])) {
        observationMgr.getObservationMyDashboard().then(res => {
          if (res?.Success) {
            const newDashboard = res?.Items?.first();

            setDashboard(newDashboard);
            if (newDashboard.CanSeeObservations)
              setMinContentWidth(50);
            else
              setMinContentWidth(70);
          }
        });
      }
      else {
        const newDashboard = {
          CanCertify: false,
          CanSeeObservations: false
        }
        setDashboard(newDashboard);
        setMinContentWidth(70);
      }
    }
  }, [observationMgr]);

  useEffect(() => {
    if (dashboard && userTitle) {
      setLoading(false);
    }
  }, [dashboard, userTitle])


  const handleOnCancel = () => {
    navigate("/my-dashboard");
    setLoadingError(null);
    setErrors(null);
  };
  const handleOpenPrint = async (row) => {
    let grabbingData = true;
    if(row.Type === 2) { // Teacher
      await certificationTeacherMgr.getSingleCertificationWithUserId(certificationTeacherMgr?.AppUserState.userProfile.Id, row.SchoolYearIdentifier, 2).then(async (result) => {
        if (result.Success) {
          setSelectedCertification(result.Items.first())
          const orgId = result.Items.first().OrganizationIds.first()
          const districtId = result.Items.first().DistrictIds.first()
          const schoolId = result.Items.first().SchoolIds.first()
          const schoolYear = result.Items.first().SchoolYearIdentifier
          await observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
            grabbingData = false;
            if (r) {
              setCertType(2);
              setObservationConfigPrint(r)
            } else console.log("No observation ConfigFound")
          })
        }
      })
    }
    else if (row.Type === 1) { // Admin
      await certificationTeacherMgr.getSingleCertificationWithUserId(certificationTeacherMgr?.AppUserState.userProfile.Id, row.SchoolYearIdentifier, 1).then(async (result) => {
        if (result.Success) {
          setSelectedCertification(result.Items.first())
          const orgId = result.Items.first().OrganizationIds.first()
          const districtId = result.Items.first().DistrictIds.first()
          const schoolId = result.Items.first().SchoolIds.first()
          const schoolYear = result.Items.first().SchoolYearIdentifier
          await observationMgr.observationConfigOperation(orgId, districtId, schoolId, schoolYear).then(r => {
            grabbingData = false;  
            if (r) {
              setCertType(1);
              setObservationConfigPrint(r)
            } else console.log("No observation ConfigFound")
          })
        }
     })
    }
    if (grabbingData === false) {
      setPrint(true);
      return true;
    }
  }


  const handleClosePrint = () => {
    // setSelectedCertification(null)
    // setObservationConfigPrint(null);
    setPrint(false);
  }
  return (
    <>
      {print && <Print close={handleClosePrint} data={selectedCertification} observationConfig={observationConfigPrint} certType={certType}  />}
      <div className="dashboard">
        <MainLayout errors={errors} validationErrors={validationErrors}>
          <ScreenWrapper
            loading={loading}
            loadingError={loadingError}
            onReturn={handleOnCancel}
          >
            <div className="dashboard-wrapper">
              {userProfile && globalConfig && (
                <div className="dashboard-profile">
                  <div className="profile-image"> {userProfile?.File ?
                    <img src={userProfile?.File?.FileUrl} alt="profile" />
                    : <FontAwesomeIcon className={"btn-icon-fa"} icon={userIcon} />
                  }</div>
                  <div className="profile-info">
                    <h1>{userProfile.DisplayName}</h1>
                    <div className="subtitle-and-icon">
                      <FontAwesomeIcon className={"btn-icon-fa"} icon={userIcon} />
                      <h6>{`${userProfile.FirstName} ${userProfile.LastName}`}</h6>
                    </div>
                    <div className="subtitle-and-icon">
                      <FontAwesomeIcon className={"btn-icon-fa"} icon={briefcaseIcon} />
                      <h6>{userTitle}</h6>
                    </div>
                  </div>
                </div>
              )}
              <div className="control-box-wrapper">

                <div className="control-box padding-left" style={{ minWidth: `${minContentWidth}%` }}>
                  {/* first section */}
                  <ContentDashboard />
                </div>
                <div className={`control-box box-flex no-padding flex-d-inherit ${dashboard ? 'vl' : ''}`}>
                  {
                    dashboard?.CanSeeObservations &&
                    <div className="control-box box-flex observation-box">
                      {/* second section */}
                      <ObservationDashboard
                        setErrors={setErrors}
                        dashboard={dashboard}
                        observationMgr={observationMgr}
                      />
                    </div>
                  }

                  <div className="vl control-box box-flex padding-left right-box">
                    {/* third section */}
                    {
                      dashboard?.CanCertify &&
                      <CertificationDashboard
                        setErrors={setErrors}
                        dashboard={dashboard}
                        organizationId={organizationId}
                        districtId={districtId}
                        schoolId={schoolId}
                        userId={userId}
                        handleOpenPrint={handleOpenPrint}
                      />
                    }
                    <NotificationDashboard
                      setErrors={setErrors}
                      organizationId={organizationId}
                      districtId={districtId}
                      schoolId={schoolId}
                      userId={userId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScreenWrapper>
        </MainLayout>
      </div>
    </>
  );
}

export default MyDashboard;
