import React from 'react';
import './footer.scss';
import nietLogo from '../../domains/contentLibrary/assets/images/NIET-logos/NIET_Logo_white.png'
import ButtonControl from '../controls/ButtonControl';
import { useFeedbackManager } from '../../hooks/useManagers';
import { GetSiteVersion } from '../../utilities/version';


const Footer = (props) => {
    var feedbackMgr = useFeedbackManager();
    return <><div className='footer'>
        <div className="align-items-center publicFooter">
            <img alt="NIET Logo" src={nietLogo} />
            <div className="publicFooter-right">
                <span className="text-white copyrightText">{GetSiteVersion()}&nbsp;&nbsp;&nbsp;&nbsp;Copyright {new Date().getFullYear()} by NIET</span>
                <ButtonControl
                    type={'footer'}
                    onClick={() => feedbackMgr?.SetShowContactUs()}>
                    Contact Us
                </ButtonControl>
            </div>
        </div>
    </div>
        {/* <ContactUs />*/}
    </>
};

export default Footer;
