import React, { useState, useEffect } from 'react';

const GetSiteVersion = () => {
  const [siteVersion, setSiteVersion] = useState('');

  useEffect(() => {
    const fetchSiteVersion = async () => {
      try {
        const response = await fetch('/version.txt');
        const content = await response.text();
        setSiteVersion(content);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    };

    fetchSiteVersion();
  }, []);

  return (
    <span id="siteVersion">Site Version {siteVersion}</span>
  );
};

export { GetSiteVersion };