import React from 'react';
import moment from 'moment';

const DatePickerControl = ({ field,
  value,
  includeTime,
  onChange,
  onBlur,
  ignoreOnChange,
  className,
  disabled,
  loading,
  minDate,
  maxDate,
  props
}) => {


  const handleOnChange = (e, field) => {
    if(!ignoreOnChange) {
      onChange(e, field);
    } else {
      const enteredMoment = moment(e.target.value);
      if (enteredMoment.isValid() && enteredMoment.year() > 1900) {
        onChange(e, field);
      }
      else if (e.target.value === '') {
        onChange(e, field);
      }
    }
  }

  return (<>
    <input id={"field_dp_" + field.FieldName}
      placeholder={field.PlaceHolder}
      onBlur={(context) => onBlur(context, field)}
      value={value}
      className={className}
      disabled={disabled}
      min={minDate}
      max={maxDate}
      type={!includeTime ? 'date' : 'datetime-local'}
      onChange={(e) => handleOnChange(e, field)}
      onKeyDown={(e) => e.preventDefault()}
      {...props} />
  </>
  );
}

export default DatePickerControl;