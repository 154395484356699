import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash';

class CertificationManager extends ItemManager {
    constructor(certificationType, globalItemState, globalAuthState, globalAppUserState) {
        super(ItemServiceFactory.ItemServiceEnum.Certification, globalAuthState, globalAppUserState, globalItemState,
            (filter, item) => {
                var hasCandidate = item?.User?.FullName?.toLowerCase()?.includes(filter?.candidate?.toLowerCase()) || (filter?.candidate === undefined || filter?.candidate === null || filter?.candidate === '');
                var hasOrg = filter?.orgs?.includes(item?.ClientId) || (filter?.orgs === null || filter?.orgs === undefined || filter?.orgs?.length === 0)
                var hasDistrict = filter?.districts?.includes(item?.DistrictId) || (filter?.districts === null || filter?.districts === undefined || filter?.districts?.length === 0)
                var hasSchool = filter?.schools?.includes(item?.SchoolId) || (filter?.schools === null || filter?.schools === undefined || filter?.schools?.length === 0)
                var hasUser = filter?.users?.includes(item?.UserId) || (filter?.users === null || filter?.users === undefined || filter?.users?.length === 0)
                var hasStatus = filter?.status == 7 ? item.Status == 9 : filter?.status == 8 ? item.Status == 9 :
                    item?.Status == filter?.status || (filter?.status === undefined || filter?.status === null || filter?.status === '' || filter?.status === 'UNK')

                var hasEEPASSID = item?.User?.SystemId == null || item?.User?.SystemId == undefined || item?.User?.SystemId?.toLowerCase()?.includes(filter?.candidate?.toLowerCase()) || (filter?.candidate === undefined || filter?.candidate === null || filter?.candidate === '');
                return ((hasCandidate || hasEEPASSID) && hasOrg && hasDistrict && hasSchool && hasUser && hasStatus);
            },
            async () => {
                return await this.runOperation("Certifications", undefined, undefined, certificationType, false, true)
            }
        );
        this._errorUtil = undefined;
        this._certificationRubricRatingMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.CertificationRubricRating, globalAuthState, globalAppUserState);
        this._certificationQuestionMapMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.CertificationQuestionMap, globalAuthState, globalAppUserState);
        this._certificationType = certificationType
    }

    async handleCertificationFailed(status, id) {
        return await this.runOperation('CertificationFailed', id, undefined, { status: status })
    }

    async resetCertificate(userId) {
        return await this.runOperation('CertificationReset', undefined, undefined, { uid: userId, sy: this._certificationType.sy, ct:this._certificationType.ct })
    }

    async grabCertificationContent(certId, userId) {
        return await this.runOperation('CertificationContent', certId, undefined, { ...this._certificationType, uid: userId })
    }

    async getSingleCertificationWithUserId(userId, schoolYear, certType) {
        let dynamicSchoolYear = schoolYear && certType ? { sy: schoolYear, ct: certType } : this._certificationType
        return await this.runOperation("Certifications", undefined, undefined, { ...dynamicSchoolYear, uid: userId })
    }


    async getCertificationRubric(certificationId) {
        return await this.runOperation("CertificationRubric", certificationId).then(r => { return r.Items.first() })
    }

    async getAllCertificationsWithCertId(certificationId) {
        return await this.runOperation("Certifications", certificationId, undefined, this._certificationType)
    }

    async saveCertificationRubricRating(item) {
        return this._certificationRubricRatingMgr.save(item)
    }

    async saveCertificationQuestion(item) {
        this._certificationQuestionMapMgr.save(item)
    }


    get DefaultItem() {
        return {
        };
    }
}

export default CertificationManager;